
.quote-images-container {

}

.quote-images-item {

}

.quote-images-item img {
  max-width: 100%;
}
