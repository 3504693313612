.approval-buttons {
  text-align: center;
}
.approval-buttons .ui.button {
  margin: 2px;
  display: block;
  width: 100%;
}

.quote-item-status .status{
   padding: 5px;
}
