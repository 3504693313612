ul.quoteitem-actions {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;
}

ul.quoteitem-actions li {
  display: inline-block;
  padding: 0.25rem 0.5rem;
  cursor: pointer;
}

.green {
  color: #24a924;
}

.red {
  color: #cc0f0f;
}
