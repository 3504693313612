.navigation-email {
  color: rgba(0, 0, 0, 0.87);
  display: block;
  font-size: 16px;
  line-height: 25px;
  position: relative;
  transition: all 450ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  margin-left: 0px;
  padding: 20px 16px 20px 72px;
}

.navigation-email-circle {
  color: rgb(255, 255, 255);
  background-color: rgb(188, 188, 188);
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 23px;
  left: 16px;
}

.navigation-email-icon {
  color: rgb(255, 255, 255);
  background-color: rgb(188, 188, 188);
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  position: absolute;
  top: 8px;
  left: 16px;
}

.sidebar .navigation-email-circle svg {
  fill: white;
  margin: 0;
  position: initial;
  width: 20px;
}
