.terms-container {
  max-width: 90%;
  margin: 0 auto;
  padding: 1rem;
  background: white;
  display: flex;
  flex-direction: column;
  margin: 5rem auto 5rem auto;
}

.terms-container p {
  margin-bottom: 1rem;
}
