.additional-approvers-container {
}

.additional-approvers-container .users {
  display: flex;
  flex-direction: column;
}

.additional-approvers-container .user {
  border-radius: 5px;
  background: #f6f9fc;
  margin-bottom: 2rem;
  margin-right: auto;
  padding: 1rem;
  box-shadow: 0 2px 4px 0 rgba(50, 50, 93, 0.1);
}

.additional-approvers-container .user .name {
  font-weight: bold;
}

.additional-approvers-container .user .company {
}

.additional-approvers-container .user .email {
}
