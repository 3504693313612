.view-quote-container {
  background: white;
  font-family: Arial;
  padding: 1rem;
  max-width: 1400px;
  margin: 0 auto;
  box-shadow: 0px 4px 4px #ccc;
}

.view-quote-header {
  text-align: center;
  margin-bottom: 2rem;
  padding-top: 2rem;
}
