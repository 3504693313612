.portal-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.portal-container {
  display: flex;
  flex: 1;
}

.portal-container .content-container {
  background: white;
  padding: 2rem 1rem 1rem;
  width: 100%;
  padding-top: 100px;
}

.portal-item-action {
  width: 200px;
}

.portal-search-bar {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50%;
}
