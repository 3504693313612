.btn {
  /* padding: 5px; */
}

/**
 * Image Previews
**/
.preview-items {
  display: flex;
  flex-wrap: wrap;
}

.preview-item {
  position: relative;
  width: 250px;
  height: 250px;
  overflow: hidden;
  align-items: center;
  display: flex;
  background: white;
  border: 2px dotted #ccc;
  padding: 5px;
  margin: 5px;
}

/**
 * Tables
**/

.sortable-header {
  font-weight: bold;
  cursor: pointer;
}

.sortable-header:hover {
  text-decoration: underline;
}

.help-text {
  margin: 1rem 0;
  font-style: italic;
  color: #ccc;
  font-size: 0.88rem;
}

.error {
  color: red;
}
