.success {
  background: #00a000;
  color: white;
}

.flash-message {
  margin: 1rem 0;
  border-radius: 3px;
  padding: 0.5rem;
}
