.admin-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
}

.admin-container {
  display: flex;
  flex: 1;
}

.admin-container .content-container {
  background: white;
  padding: 2rem 1rem 1rem;
  width: 100%;
  padding-top: 100px;
}

/**
 * Mobile Menu
**/

.mobile-burger-menu {
  position: absolute;
  font-family: "Ionicons";
  background: white;
  color: black;
  font-size: 2rem;
  top: 0;
  left: 0;
  width: 50px;
  text-align: center;
  height: 48px;
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 769px) {
  .mobile-burger-menu {
    display: none;
  }
}

.mobile-menu {
  position: absolute;
  background: #f8f8f8;
  height: 100vh;
  top: 48px;
  z-index: 10;
  width: 100%;
  left: -100%;
  transition: left 0.5s ease-in-out;
}

.mobile-menu.mobile-menu-true {
  left: 0;
}

.mobile-menu ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.mobile-menu li {
  border-bottom: 1px solid gray;
}

.mobile-menu li a {
  font-size: 1.5rem;
  color: #637282;
  display: block;
  padding: 1rem;
  text-decoration: none;
}

/**
 * Logo
**/

.logo {
  padding-top: 10px;
  padding-left: 1rem;
}

/**
 * Sidebar
**/

.sidebar {
  background-color: #f8fafb;
  width: 300px;
  padding: 0 0 0 0;
}

@media (max-width: 768px) {
  .sidebar {
    display: none;
  }
}

.sidebar ul {
  list-style: none;
  margin: 25px 0 0;
  padding: 0 0 0 1rem;
}

.sidebar a {
  display: block;
  color: #637282;
  text-decoration: none;
  line-height: 3rem;
  font-size: 1.1rem;
  padding-left: 0.5rem;
  transition: padding-left 0.45s;
}

.sidebar a:hover {
  /*padding-left: 1rem;*/
  text-decoration: none;
  color: #cd1313;
}
