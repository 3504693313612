.admin-top-wrapper {
  background: #dedede;
  text-align: right;
  padding: 0;
  display: none;
}

.admin-top-wrapper ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.admin-top-wrapper li {
  display: inline-block;
}

.admin-top-wrapper a {
  color: #000;
  display: block;
  text-decoration: none;
  line-height: 3rem;
  font-size: 0.875rem;
  padding: 0 1.5rem;
}

.admin-top-wrapper a:hover {
  background: #cd1313;
}
