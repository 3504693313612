.quotesList {
  width: 100%;
}

.quotesList table {
  width: 100%;
}

.quotesList table th {
  text-align: left;
}

.quoteItemRow {
}

.quotes-list-container {
  font-size: 14px;
}

.quotes-list-container .ui.table thead {
  display: none;
}

@media only screen and (min-width: 769px) {
  .quotes-list-container .ui.table thead {
    display: table-header-group;
  }
  .quotes-list-container {
    display: block;
  }
}

.addLineItem {
  text-align: right;
}

.quote-edit {
  font-size: 1rem;
  font-size: inherit;
}

th.quote-item-no {
  text-align: center;
  width: 150px;
  text-align: center;
}

th.quote-item-name {
  text-align: center;
  width: 230px;
  text-align: center;
}

th.quote-item-status {
  width: 180px;
}

th.quote-item-approved-on {
  width: 100px;
}

th.quote-item-actions {
  width: 180px;
}

th.quote-term {
  text-align: center;
  width: 80px;
  text-align: center;
}

th.quote-item-created {
  text-align: center;
  width: 120px;
  text-align: center;
}

th.quote-item-action {
  text-align: center;
  width: 150px;
  text-align: center;
}

.quote-title-column {
}

.quote-title-column .label {
  width: 150px;
}

.quote-section {
  background: #f7f7f7;
  padding: 1rem;
  border-radius: 10px;
  margin: 1rem 0;
}

.quote-btn {
  background: none;
  padding: 5px;
  font-size: 0.875rem;
  color: #c82222;
}

.quote-btn.edit {
}

.cc-me {
  max-width: 150px;
  margin: 2rem auto;
}

/**
 * QuoteTotal.js
**/
.quote-total {
  font-size: 1.5rem;
  text-align: right;
  padding: 1rem 0;
}

.quote-approval-needed {
  font-size: 1rem;
  color: red;
  margin: 1rem 0;
}

.quote-select-user {
  text-align: left;
  display: flex;
  justify-content: flex-end;
}

.quote-select-user .FormInput {
  width: 300px;
}

/**
 * Top Panel
**/

.actions-top-panel {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0.5rem;
}

.actions-top-panel .left-actions {
  margin-right: auto;
}

.actions-top-panel .item {
  margin: 0.5rem 0;
}

.quote-breadcrumbs {
  padding: 1rem 0;
}

.signature-container {
  width: 900px;
  /* margin: 0 auto; */
}
.signature-box {
  background: white;
}

/**
 * Media Queries
**/

@media (max-width: 480px) {
  .quote-item-description {
    display: none;
  }
  .quote-item-status {
    display: none;
  }
}
