.SearchBar--container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.SearchBar--text {
  margin: 0 0.25rem 0 0;
  width: 100%;
}

.SearchBar--button {
}

.SearchBar--form {
  display: flex;
  flex: 1;
}
