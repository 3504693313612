

.table-group {
  margin: 4rem 0;
}

.table-group .inverted a {
  color: #fff;
}

.dashboard-header {
  display: flex;
}

section.dashboard-section {
  background: #f5f5f5;
  padding: 2rem;
  border-radius: 1rem;
  border: 1px solid #ebebeb;
}

.dashboard-charts-container {
  display: flex;
  justify-content: center;
  padding: 2rem 0;
}

.dashboard-charts-container .item {
  padding: 1rem;
  margin: 2rem;
}

.dashboard-header .header {
  flex: 1;
}

.jump-to-quotes {
  flex: 0 0 auto;
}

.counts-container {
  text-align: center;
}

.counts-container .item {
  display: inline-block;
  width: 600px;
  padding: 1rem;
  margin: 2rem;
}

.counts-container .item-full {
  width: 1000px;
}

.report-item {
  background: #f4f4f4;
  border-radius: 5px;
}

.supervisor-reports {
  border-top: 2rem solid #be0000;
  padding-top: 5rem;
  margin: 5rem 0;
}
