/**
 * Filters
**/

.filters-panel {
  background: #e6e6e6;
  display: flex;
  align-items: center;
  border-radius: 5px;
  padding: 0.5rem;
  justify-content: flex-end;
  visibility: hidden;
}

.filters-panel.open {
  visibility: visible;
}

.filters-panel .dropdown {
  flex: 1;
  margin: 0 1rem;
}
