.tbl-quote-header {
  width: 100%;
  line-height: 1.6rem;
  display: flex;
  flex-direction: column;
}

@media only screen and (min-width: 769px) {
  .tbl-quote-header {
    flex-direction: row;
  }
}

.tbl-quote-header .tbl-quote-header-item {
  flex: 1;
  margin: 1rem;
}

@media only screen and (min-width: 769px) {
  .tbl-quote-header .tbl-quote-header-item {
    margin: 0;
  }
}

.tbl-job-details {
  text-align: left;
  padding-left: 15%;
}

/**
 * Main Quote
**/
.tbl-quote {
  width: 100%;
  border-collapse: collapse;
  line-height: 2rem;
  margin-top: 20px;
}

.tbl-headers {
  background: #d1e9ff;
}

.tbl-quote-row {
  display: flex;
  flex-direction: column;

  border: 1px solid #e1e1e1;
  padding: 0.5rem;
}

@media only screen and (min-width: 769px) {
  .tbl-quote-row {
    flex-direction: row;
  }
}

.tbl-quote-item {
  flex: 1;
}

.tbl-quote th {
  font-weight: bold;
  text-align: left;
}

.tbl-quote tr:nth-child(odd) {
  background: #e1f4ff;
}

.tbl-quote td {
  margin: 0;
}

.quotes-notes-container {
  margin: 2rem 0;
}

.quotes-notes-container label {
  font-weight: bold;
}
.quotes-notes {
}

.quotes-acceptance {
  text-align: center;
}
