
.login-container {
  height: 100%;
  display: flex;
  flex: 1;
}


.customer-login-box {
  /*background: rgba(255, 255, 255, 0.95);*/
  padding: 1rem;
  border-radius: 5px;
  align-self: center;
  text-align: center;
  margin: auto;
  flex-direction: column;
  display: flex;
}

.customer-login-box .textInput {
  display: block;
  font-size: 1.25rem;
  border-radius: 5px;
  border: 1px solid #eaeaea;
  padding: 0.5rem;
  margin: 0 0.5rem 1rem 0;
  outline: 0;
  width: 100%;
}
