.quote-actions-container {
  display: flex;
  align-items: center;
  flex-direction: column;
}

@media only screen and (min-width: 768px) {
  .quote-actions-container {
    flex-direction: row;
  }
}

.action-panel {
  flex: 1;
}

@media only screen and (min-width: 768px) {
  .action-panel {
    flex: 0 0 180px;
    margin-right: 40px;
    padding: 0 0 0 20px;
  }
}

.action-panel button {
  padding: 5px;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .action-panel button {
    width: 200px;
  }
}

.action-panel .item {
  margin: 10px 0;
  display: block;
  width: 100%;
}

@media only screen and (min-width: 768px) {
  .action-panel .item {
    width: auto;
  }
}

.actions-dropdown-container,
.actions-dropdown.ui {
  width: 100%;
  text-align: center;
}
@media only screen and (min-width: 768px) {
  .actions-dropdown-container,
  .actions-dropdown.ui {
    width: auto;
    text-align: initial;
  }
}

.action-panel .submit-quote {
  margin-left: auto;
  display: flex;
}

.action-panel .submit-quote button {
  margin: 0 5px;
}

/* .quote-submit-actions {
  display: flex;
} */
